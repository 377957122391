<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import axios from "axios";

export default {
  name: "FormBuyProduct",
  page: {
    title: "Sale Product",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Form Sale Product",
      items: [
        {
          text: "Home",
          href: "/home"
        },
        {
          text: "List Checkout Product",
          href: "/buy-product"
        },
        {
          text: "Sale Product",
          active: true
        }
      ],
      wooCommereceProduct: [],
      selectedWooProduct: null,
      product: {
        name: null,
        price: null,
        quantity: null,
        stock: null
      },
      form: {
        user: null, 
        location: null,
        products: [],
        paymentMethod: null,
        couponCode: null,
        coupon: null
      },
      isError: false,
      errorMessage: null,
      users: [],
      isLoadingUser: false,
      isUserNotSelected: false,
      optionsPaymentMethod: ['cash', 'debit', 'credit card', 'transfer', 'OVO', 'go pay', 'shopee pay'],
      isPaymentMethodNotSelected: false,
      islocationsNotSelected: false,
      locations: [],
      disableLocation: (this.$store.getters.getStaffLocationId)? true : false,
      isQtyLargerThanStock: false,
      isCouponInValid: false
    };
  },
  mounted() {
    this.findLocation()
  },
  computed: {
    totalProduct() {
      if (this.form.products.length) {
        let total = 0
        this.form.products.forEach(product => {
          total += product.price * product.quantity
        })
        return total
      } else {
        return 0
      }
    },
    discount() {
      if (this.form.coupon !== null) {
        return this.form.coupon.amount
      } else {
        return 0
      }
    },
    total() {
      return this.totalProduct - this.discount
    }
  },
  methods: {
    formatRupiah() {
      const angka = this.convertToAngka(this.product.price)
      const rupaiah = this.convertToRupiah(angka, false)
      this.product.price = rupaiah
    },
    findLocation() {
      this.$axios
        .get(`location`, {
          params: {
            sortBy: "name",
            sortType: 1,
            limit: 500,
          },
        })
        .then((response) => {
          console.log(response)
          let result = response.data.result.location;
          this.locations = result;
          if (this.$store.getters.getStaffLocationId) {
            this.form.location = this.locations.find(location => location.locationId == this.$store.getters.getStaffLocationId)
            this.findProduct(this.form.location)
          }
        });
    },
    findUser(query) {
      console.log(query);
      this.isLoadingUser = true;
      this.$axios.get(`user`, {
        params: {
          search: query,
          userWoo: 1,
          sortBy: "name",
          sortType: 1,
        },
      }).then((response) => {
        console.log(response)
        let result = response.data.result.users;
        this.users = result.map( user => {
          return Object.assign(user, {
            fullname: user.firstname + ' ' + user.lastname
          })
        });
        this.isLoadingUser= false;
      });
    },
    addToCart() {
      // validate quantity
      if (Number(this.product.quantity) > this.product.stock) {
        this.isQtyLargerThanStock = true
        return false
      }
      // add to carts
      this.isQtyLargerThanStock = false
      this.form.products.push({
        id: this.product.id,
        name: this.product.name,
        price: this.convertToAngka(this.product.price),
        quantity: Number(this.product.quantity),
        priceStr: this.product.price,
        subTotalStr: this.convertToRupiah(this.convertToAngka(this.product.price) * this.product.quantity, false),
        stock: this.product.stock
      })
      // remove product form list products woo commerece
      const indexProduct = this.wooCommereceProduct.findIndex(prod => prod.id == this.product.id)
      if (indexProduct >= 0) {
        this.wooCommereceProduct.splice(indexProduct, 1)
      }
      // clear form search product
      this.product = {
        name: null,
        price: null,
        quantity: null,
        stock: null
      }
      this.selectedWooProduct = null
      this.disableLocation = true
    },
    selectProduct(data){
      this.product = {
        id: data.id,
        name: data.name,
        price: this.convertToRupiah(data.price, false),
        quantity: 1,
        stock: data.stock
      }
    },
    findProduct(location) {
      axios.post(this.productUrl, {
        page: 1,
        per_page: 100,
        segment: {
          category: [],
          collection: [],
          concern: []
        },
        search: "",
        discount: "",
        order: "",
        orderby: "",
        min_price: "",
        max_price: "",
        location: location.locationId
      }).then( response => {
        if (response.data.status != 'fail') {
          this.wooCommereceProduct = response.data.data.products.filter(item => item.stock > 0).map(item => {
            return {
              id: item.id,
              name: item.title,
              price: item.price.default,
              stock: item.stock
            }
          }) 
        }
      })
        
    },
    deleteProduct(index) {
      // add product in cart to product woo commerece
      const product = this.form.products[index];
      this.wooCommereceProduct.push({
        id: product.id,
        name: product.name,
        price: product.price,
        stock: product.stock
      })
      // remove product in cart
      this.form.products.splice(index, 1)
    },
    checkout() {
      this.isUserNotSelected = this.form.user === null ? true : false;
      this.isPaymentMethodNotSelected = (this.form.paymentMethod === null)? true : false;

      if (this.isUserNotSelected || this.isPaymentMethodNotSelected) {
        return false
      }

      let param = new FormData();
      param.append("location", this.form.location.locationId);
      param.append("user", this.form.user.userId);
      param.append("payment_method", this.form.paymentMethod);
      param.append("coupon", this.form.couponCode);
      param.append("discount", this.discount);
      this.form.products.forEach( p => {
        param.append('product_id', p.id)
        param.append('product_name', p.name)
        param.append('product_quantity', p.quantity)
        param.append('product_price', p.price)
      })

      this.$axios
        .post(`/checkout-product/buy`, param)
        .then(() => {
          Swal.fire("Success", "Product Checkouted Successfully", "success");
          this.$router.push(`/buy-product`);
        })
        .catch((e) => {
          if (e.response) {
            this.isError = true;
            this.errorMessage = e.response.data.message;
          }
        });
    },
    validateCoupon() {
      if (this.form.couponCode == null || this.form.couponCode == "") {
        this.isCouponInValid = true;
      } else {
        this.isCouponInValid = false;

      let param = new FormData()
      param.append("coupon", this.form.couponCode);
      param.append("user", this.form.user.userId);
      this.form.products.forEach( p => {
        param.append('product_id', p.id)
        param.append('product_name', p.name)
        param.append('product_quantity', p.quantity)
        param.append('product_price', p.price)
      })

      this.$axios
        .post(`/checkout-product/validate-coupon`, param)
        .then((response) => {
          console.log(response)
          this.isCouponInValid = false
          this.form.coupon = response.data.result.coupon
        })
        .catch((error) => {
          console.log(error)
          this.isCouponInValid = true
          this.form.couponCode = null
          this.form.coupon = null
        });
      }
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              v-model="isQtyLargerThanStock"
              variant="danger"
              class="mt-3"
              dismissible
            >Insufficient product stock</b-alert>
            <form action="#" @submit.prevent="addToCart">
              <div class="form-group">
                <label>Location</label>
                <multiselect
                  :class="{ 'is-invalid': islocationsNotSelected }"
                  v-model="form.location"
                  label="name"
                  track-by="locationId"
                  placeholder="Select One"
                  open-direction="bottom"
                  :maxHeight="500"
                  :options="locations"
                  :multiple="false"
                  :internal-search="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :hide-selected="false"
                  :allowEmpty="false"
                  :disabled="disableLocation"
                  @select="findProduct"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <strong>{{ option.name }}</strong>
                  </template>
                  <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </multiselect>
                <div v-if="islocationsNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>
              <div class="form-group">
                <label>Search Product From Surface +</label>
                <multiselect
                  v-model="selectedWooProduct"
                  label="name"
                  track-by="name"
                  placeholder="Typen Product Name"
                  open-direction="bottom"
                  :options="wooCommereceProduct"
                  :multiple="false"
                  :internal-search="true"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :hide-selected="false"
                  :allowEmpty="false"
                  @select="selectProduct"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <strong>{{ option.name }}</strong>
                  </template>
                </multiselect>
              </div>
              <div class="form-group">
                <label>Quantity</label>
                <input
                  v-model="product.quantity"
                  class="form-control"
                  type="number"
                  step="1"
                  min="1"
                  required
                />
              </div>
              <div class="form-group">
                <label>Price</label>
                <b-input-group prepend="Rp.">
                  <input
                    v-model="product.price"
                    type="text"
                    class="form-control"
                    name="price"
                    @keyup="formatRupiah"
                    required
                  />
                </b-input-group>
              </div>
              <button type="submit" class="btn btn-primary mt-2 float-right">Add To Cart</button>              
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>
    </div>
    <div class="row" v-if="this.form.products.length">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              v-model="isError"
              variant="danger"
              class="mt-3"
              dismissible
            >{{errorMessage}}</b-alert>
            <h3 class="mb-3">Carts</h3>
            <form @submit.prevent="checkout">
              <div class="form-group">
                <label>User</label>
                <multiselect
                  :class="{ 'is-invalid': isUserNotSelected }"
                  v-model="form.user"
                  label="fullname"
                  track-by="userId"
                  placeholder="Type to search"
                  open-direction="bottom"
                  :options="users"
                  :multiple="false"
                  :searchable="true"
                  :loading="isLoadingUser"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :options-limit="300"
                  :limit="3"
                  :max-height="600"
                  :show-no-results="false"
                  :hide-selected="false"
                  @search-change="findUser"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <strong>{{ option.firstname +' '+ option.lastname  }}</strong>
                  </template>
                  <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </multiselect>
                <div v-if="isUserNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>
              <div class="form-group">
                <label>Coupon</label>
                <b-input-group :class="{ 'is-invalid': isCouponInValid }">
                  <input
                    v-model="form.couponCode"
                    type="text"
                    class="form-control"
                    name="voucher"
                    placeholder="input voucher code here"
                    :class="{ 'is-invalid': isCouponInValid }"
                    @keyup.enter="validateCoupon"
                    :disabled="!form.user"
                  />
                
                  <b-input-group-append>
                    <b-button variant="outline-primary" @click="validateCoupon">Validate</b-button>
                  </b-input-group-append>
                </b-input-group>
                <div v-if="isCouponInValid" class="invalid-feedback">
                  <span>Coupon Not Valid</span>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-lg-6">
                      <label>Product Name</label>
                    </div>
                    <div class="col-lg-1">
                      <label>Quantity</label>
                    </div>
                    <div class="col-lg-2">
                      <label>Price</label>
                    </div>
                    <div class="col-lg-2">
                      <label>Subtotal</label>
                    </div>
                  </div>
                  <div class="row mb-2" v-for="(product, index) in form.products" :key="index">
                    <div class="col-lg-6">
                      <input
                        v-model="product.name"
                        class="form-control"
                        type="text"
                        readonly
                      />
                    </div>
                    <div class="col-lg-1">
                      <input
                        v-model="product.quantity"
                        class="form-control"
                        type="number"
                        readonly
                      />
                    </div>
                    <div class="col-lg-2">
                      <input
                        v-model="product.priceStr"
                        class="form-control"
                        type="text"
                        readonly
                      />
                    </div>
                    <div class="col-lg-2">
                      <input
                        v-model="product.subTotalStr"
                        class="form-control"
                        type="text"
                        readonly
                      />
                    </div>
                    <div class="col-lg-1">
                      <button type="button" @click="deleteProduct(index)" class="btn btn-sm btn-danger">Remove</button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-9">
                      <label>Sub Total</label>
                    </div>
                    <div class="col-lg-3">
                      <h4>Rp. {{ totalProduct | convertToRupiah }}</h4>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-9">
                      <label>Discount</label>
                    </div>
                    <div class="col-lg-3">
                      <h4>Rp. {{ discount | convertToRupiah }}</h4>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-9">
                      <label>Total</label>
                    </div>
                    <div class="col-lg-3">
                      <h4>Rp. {{ total | convertToRupiah }}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Payment Method</label> 
                <multiselect :class="{ 'is-invalid': isPaymentMethodNotSelected }" v-model="form.paymentMethod" deselect-label="Can't remove this value" placeholder="Select one" :options="optionsPaymentMethod" :searchable="false" :allow-empty="false">
                  <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
                  <template slot="option" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
                </multiselect>
                <div v-if="isPaymentMethodNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div>
              <button type="submit" class="btn btn-primary mt-2 float-right">Checkout</button>              
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
input:read-only {
  background-color: #dbd3d3;
}
</style>
